<template>
  <div>
    <a-form :form="form" @submit="submitForm">
      <a-row class="contract-files" :gutter="20">
        <a-row id="contract-files" :gutter="20">
          <a-col :span="24">
            <a-row :gutter="20">
              <a-col class="mb-20" :span="6">
                <div class="label mb-10">Frente do Cartão</div>
                <div v-if="!doc.front">
                  <a-upload
                    name="file"
                    :multiple="false"
                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${tempContract.id}&sub-folder=credit-card-front`"
                    :remove="removeUploadedFile"
                    :headers="{
                      authorization: `Bearer ${this.$store.state.token}`,
                    }"
                    @change="uploadedFile"
                    v-model="doc.front"
                    :disabled="!edit"
                  >
                    <a-button :disabled="!edit" type="dashed" size="large">
                      <a-icon type="credit-card" />
                    </a-button>
                  </a-upload>
                </div>

                <div v-if="doc.front" class="file-link relative">
                  <a v-if="doc.front" :href="doc.front" target="_blank">
                    <img
                      v-if="checkFileType(doc.front)"
                      :src="doc.front"
                      style="max-width: 260px"  alt="img"
                    />
                    <a-icon
                      v-else
                      class="cblue"
                      type="credit-card"
                      style="font-size: 38px"
                    />
                  </a>

                  <a-icon
                    class="close-circle"
                    type="close-circle"
                    v-if="doc.front && edit"
                    @click="removefile('credit-card-front')"
                  />
                </div>
              </a-col>

              <a-col :span="6">
                <a-form-item>
                  <div class="label">Data de criação</div>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY HH:mm"
                    :showToday="false"
                    :allowClear="false"
                    :disabled="true"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_credit_card_front_created',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>

              <a-col :span="6">
                <a-form-item>
                  <div class="label">Data de validade</div>

                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY"
                    :showToday="false"
                    :allowClear="true"
                    :disabled="!edit"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_credit_card_front_expires_at',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="24">
            <a-row :gutter="20">
              <a-col class="mb-20" :span="6">
                <div class="label mb-10">Verso do Cartão</div>
                <div v-if="!doc.back">
                  <a-upload
                    :multiple="false"
                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${tempContract.id}&sub-folder=credit-card-back`"
                    :headers="{
                      authorization: `Bearer ${this.$store.state.token}`,
                    }"
                    @change="uploadedFile"
                    v-model="doc.back"
                    :disabled="!edit"
                  >
                    <a-button type="dashed" :disabled="!edit" size="large">
                      <a-icon type="credit-card" />
                    </a-button>
                  </a-upload>
                </div>

                <div v-if="doc.back" class="file-link relative">
                  <a v-if="doc.back" :href="doc.back" target="_blank">
                    <img
                      v-if="checkFileType(doc.back)"
                      :src="doc.back"
                      style="max-width: 260px"
                    />
                    <a-icon
                      v-else
                      class="cblue"
                      type="credit-card"
                      style="font-size: 38px"
                    />
                  </a>

                  <a-icon
                    class="close-circle"
                    type="close-circle"
                    v-if="doc.back && edit"
                    @click="removefile('credit-card-back')"
                  />
                </div>
              </a-col>

              <a-col :span="6">
                <a-form-item>
                  <div class="label">Data de criação</div>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY HH:mm"
                    :showToday="false"
                    :allowClear="false"
                    :disabled="true"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_credit_card_back_created',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>

              <a-col :span="6">
                <a-form-item>
                  <div class="label">Data de validade</div>

                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY"
                    :showToday="false"
                    :allowClear="true"
                    :disabled="!edit"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_credit_card_back_expires_at',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>

          <a-col :span="24">
            <a-row :gutter="20">
              <a-col class="mb-20" :span="6">
                <div class="label mb-10">Slip de pagamento</div>
                <div v-if="!doc.slip">
                  <a-upload
                    :multiple="false"
                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=contracts&id=${tempContract.id}&sub-folder=credit-card-slip`"
                    :disabled="!edit"
                    :headers="{
                      authorization: `Bearer ${this.$store.state.token}`,
                    }"
                    @change="uploadedFile"
                    v-model="doc.slip"
                  >
                    <a-button type="dashed" :disabled="!edit" size="large">
                      <a-icon type="profile" />
                    </a-button>
                  </a-upload>
                </div>

                <div v-if="doc.slip" class="file-link relative">
                  <a v-if="doc.slip" :href="doc.slip" target="_blank">
                    <img
                      v-if="checkFileType(doc.slip)"
                      :src="doc.slip"
                      style="max-width: 260px"
                    />
                    <a-icon
                      v-else
                      class="cblue"
                      type="profile"
                      style="font-size: 38px"
                    />
                  </a>

                  <a-icon
                    class="close-circle"
                    type="close-circle"
                    v-if="doc.slip && edit"
                    @click="removefile('credit-card-slip')"
                  />
                </div>
              </a-col>

              <a-col :span="6">
                <a-form-item>
                  <div class="label">Data de criação</div>
                  <a-date-picker
                    class="travel-input"
                    placeholder="Selecione"
                    format="DD/MM/YYYY HH:mm"
                    :showToday="false"
                    :allowClear="false"
                    :disabled="true"
                    v-mask="'##/##/####'"
                    v-decorator="[
                      'contract_files_credit_card_slip_created',
                      {
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-date-picker>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>

        <a-col class="actions" :span="24">
          <a-button
            v-if="edit"
            @click="edit = false"
            class="ml-10 cancel"
            type="primary"
          >
            <a-icon type="close" />
          </a-button>

          <a-button
            v-if="!edit"
            @click="edit = true"
            class="ml-10 edit"
            type="primary"
          >
            <a-icon type="edit" />
          </a-button>

          <a-button
            v-if="edit"
            html-type="submit"
            class="ml-10 save"
            type="primary"
            style="padding: 0px 6px"
            :loading="loading"
          >
            <img
              v-show="!loading"
              src="@/assets/images/dashboard/sales/disket.png"
              alt="save"
            />
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
export default {
  props: {
    tempContract: Object,
    sale: Object,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      edit: false,
      loading: false,
      doc: {
        front: "",
        back: "",
        slip: "",
      },
    };
  },
  mounted() {
    this.doc.front = this.tempContract["contract_files_credit_card_front"];
    this.doc.back = this.tempContract["contract_files_credit_card_back"];
    this.doc.slip = this.tempContract["contract_files_credit_card_slip"];

    this.form.setFieldsValue({
      [`contract_files_credit_card_front_created`]:
        this.tempContract["contract_files_credit_card_front_created"],
      [`contract_files_credit_card_front_expires_at`]:
        this.tempContract["contract_files_credit_card_front_expires_at"],
      [`contract_files_credit_card_back_created`]:
        this.tempContract["contract_files_credit_card_back_created"],
      [`contract_files_credit_card_back_expires_at`]:
        this.tempContract["contract_files_credit_card_back_expires_at"],

      [`contract_files_credit_card_slip_created`]:
        this.tempContract["contract_files_credit_card_slip_created"],
    });
  },
  methods: {
    removefile(fileName) {
      if (fileName == "credit-card-front") {
        this.doc.front = "";
        this.form.setFieldsValue({
          [`contract_files_credit_card_front_created`]: "",
          [`contract_files_credit_card_front_expires_at`]: "",
        });
      }

      if (fileName == "credit-card-back") {
        this.doc.back = "";
        this.form.setFieldsValue({
          [`contract_files_credit_card_back_created`]: "",
          [`contract_files_credit_card_back_expires_at`]: "",
        });
      }

      if (fileName == "credit-card-slip") {
        this.doc.slip = "";
        this.form.setFieldsValue({
          [`contract_files_credit_card_slip_created`]: "",
        });
      }
    },
    uploadedFile(response, e) {
      e;

      if (response.file.response != undefined) {
        if (response.file.response.url != undefined) {
          let urlPieces = response.file.response.url.split("/");

          if (urlPieces[5] == "credit-card-front") {
            this.doc.front = response.file.response.url;
            this.tempContract["contract_files_credit_card_front_created"] =
              response.file.response.created;
            setTimeout(() => {
              this.form.setFieldsValue({
                [`contract_files_credit_card_front_created`]:
                  response.file.response.created,
              });
            }, 100);
          }

          if (urlPieces[5] == "credit-card-back") {
            this.doc.back = response.file.response.url;
            this.tempContract["contract_files_credit_card_back_created"] =
              response.file.response.created;
            setTimeout(() => {
              this.form.setFieldsValue({
                [`contract_files_credit_card_back_created`]:
                  response.file.response.created,
              });
            }, 100);
          }

          if (urlPieces[5] == "credit-card-slip") {
            this.doc.slip = response.file.response.url;
            this.tempContract["contract_files_credit_card_slip_created"] =
              response.file.response.created;
            setTimeout(() => {
              this.form.setFieldsValue({
                [`contract_files_credit_card_slip_created`]:
                  response.file.response.created,
              });
            }, 100);
          }
        }
      }
    },
    checkFileType(fileUrl) {
      let flag = false;
      let filePieces = fileUrl.split(".");

      if (
        filePieces[filePieces.length - 1] == "jpg" ||
        filePieces[filePieces.length - 1] == "png"
      ) {
        flag = true;
      }

      return flag;
    },
    submitForm(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        values.id = this.tempContract.id;
        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        values.contract_files_credit_card_front = this.doc.front;
        values.contract_files_credit_card_back = this.doc.back;
        values.contract_files_credit_card_slip = this.doc.slip;

        if (!err) {
          this.loading = true;
          this.$http
            .post("/contract/update-multi-meta", values)
            .then(({ data }) => {
              this.$message.success(data.message);

              this.edit = false;
              this.loading = false;

              this.$http
                .post("/log/create", {
                  user_id: this.$store.state.userData.id,
                  module_id: this.tempContract.id,
                  module: "contract",
                  action: "upload-id-card",
                  description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} realizou o upload do cartão de crédito no contrato #${this.tempContract.id}.`,
                })
                .then(({ data }) => {
                  data;
                });

              this.$emit("reloadSale");
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loading = false;
            });
        } else {
          this.$message.error("Prrencha todos os campos.");
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.contract-files
  .file-link
    display: inline-block
    .close-circle
      font-size: 19px
      color: red
      position: absolute
      right: -11px
      top: 0px
      border-radius: 10px
      background: #FFF
      cursor: pointer
  .label
    z-index: 4
    font-size: 10px
    font-weight: 500
    color: #aaa
    transition: .3s
    line-height: 1
    overflow: hidden
    text-overflow: ellipsis
    width: 100%
    white-space: nowrap
  .actions
    text-align: right
    border-top: 1px solid #eee
    padding: 10px 0 0 0
    margin-top: 20px
    .edit
      background: #f27935
      border-color: #f27935
      padding: 5px 10px
    .cancel
      background: #59595b
      border-color: #59595b
      padding: 5px 10px
    .update
      background: #2ecc71
      border-color: #2ecc71
      padding: 5px 10px
      width: 32px
    .save
      background: #00acec
      border-color: #00acec
      padding: 0 6px
      width: 32px
      text-align: center
</style>
